import Head from 'next/head';

const baseUrl = process.env.NEXT_PUBLIC_SITEURL;

const OrganizationStructuredData = () => (
  <Head>
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: baseUrl,
          logo: `${baseUrl}/icons/denham-logo-header.svg`,
        }),
      }}
    />
  </Head>
);

export default OrganizationStructuredData;
