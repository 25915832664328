import Layout from 'components/layout/layout';
import Sections from 'components/sections';
import SEO from 'components/SEO';
import PageFitGuide from 'components/pages/fit-guide';
import dynamic from 'next/dynamic';
import OrganizationStructuredData from 'components/organization-structured-data';
import HighstreetAppMetaUrl from 'components/highstreet-app-meta-url';
import PageCareers from '../globalModules/careers';
import PageFaq from '../globalModules/faq';
import PageLookbook from '../globalModules/lookbook';
import Newsletter from '../newsletter';

const PageStores = dynamic(() => import('../globalModules/stores'));
const Search = dynamic(() => import('../globalModules/search'));

const renderGlobalModulePage = (name, data, locale, currency) => {
  const isNewsletterPage = name.startsWith('Newsletter page');

  if (isNewsletterPage) return <Newsletter resources={data} currency={currency} />;

  if (name === 'Search') return <Search resources={data} />;

  if (name === 'Lookbook' || name.startsWith('Lookbook - '))
    return <PageLookbook resources={data} />;

  const legacyData = { ...data, globalModuleCollection: { items: [data.globalModule] } };

  if (name.startsWith('Fit-Guide-')) return <PageFitGuide resources={legacyData} name={name} />;

  switch (name) {
    case 'Stores':
      return <PageStores resources={legacyData} locale={locale} />;
    case 'Careers':
      return <PageCareers resources={legacyData} />;
    case 'FAQ':
      return <PageFaq resources={legacyData} />;
    default:
      return null;
  }
};

const getAppSlug = page => {
  const slug = page?.slug;
  const globalModuleName = page?.globalModule?.name;

  if (slug === '/') return 'home';
  if (globalModuleName === 'Lookbook' || globalModuleName?.startsWith('Lookbook - '))
    return 'lookbook';

  return null;
};

const PageGeneral = ({
  page,
  previewData,
  header,
  footer,
  cart,
  locale,
  currency,
  newsletterPopup,
  uspBanner,
  storeLocaleSelector,
}) => {
  const isAboutPage = page?.seo?.localeSlugs?.en === '/about-denham';

  const appSlug = getAppSlug(page);

  return (
    <>
      {appSlug && <HighstreetAppMetaUrl url={appSlug} />}
      {isAboutPage && <OrganizationStructuredData />}
      {page?.seo && <SEO seo={page.seo} />}
      <Layout
        page={page}
        header={header}
        footer={footer}
        cart={cart}
        previewData={previewData}
        locale={locale}
        currency={currency}
        newsletterPopup={newsletterPopup}
        uspBanner={uspBanner}
        storeLocaleSelector={storeLocaleSelector}
      >
        {page?.globalModule &&
          renderGlobalModulePage(page.globalModule.name, page, locale, currency)}
        {page?.sectionsCollection && <Sections data={page?.sectionsCollection} />}
      </Layout>
    </>
  );
};

export default PageGeneral;
