import { useEffect, useState } from 'react';
import SiteImage from 'components/shared/image';
import { useRouter } from 'next/router';
import { getResource, getResourceImage, getResourceText } from 'lib/resources';
import { RichText, Select } from 'components/shared';
import richTextOptions from 'components/sections/articles/richTextOptions';
import { useCustomer } from 'hooks';
import getStoreVariable from 'lib/storeLocales/getStoreVariable';
import getStoreByCurrency from 'lib/storeLocales/getStoreByCurrency';
import { trackInWindow, trackEvents } from 'hooks/tracking/track';
import getRecommendedCountryCodes from 'lib/getRecommendedCountryCodes';
import { useStoreContext } from 'contexts';

const initialValues = {
  email: '',
  firstName: '',
  gender: '',
  city: '',
  country: '',
  birthDate: '',
};

const getQueryValues = query =>
  Object.keys(query).reduce(
    (queryValues, queryKey) =>
      Object.keys(initialValues).includes(queryKey) && query[queryKey] !== ''
        ? { ...queryValues, [queryKey]: query[queryKey] }
        : queryValues,
    {}
  );

const Newsletter = ({
  resources: {
    globalModule: {
      name: formName,
      resourcesCollection: { items: resources },
      defaultResources,
      countries = {},
    },
  },
  currency,
}) => {
  const router = useRouter();
  const { query } = router;
  const { customer } = useCustomer();
  const { store, locale } = useStoreContext();

  const [values, setValues] = useState(initialValues);
  const [isCompleted, setCompleted] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const inputChange = ({ target: { name, value } }) =>
    setValues(prevState => ({ ...prevState, [name]: value }));

  const t = (name, fallback) => {
    const text = getResourceText(resources)(name);
    const defaultText = getResourceText(defaultResources)(name, fallback);
    return text || defaultText;
  };
  const getImage = name =>
    getResourceImage(resources)(name) || getResourceImage(defaultResources)(name);
  const getFormLabel = name => t(`Form ${name} label`);

  const disclaimerRichText =
    getResource(resources)('Disclaimer')?.richText ||
    getResource(defaultResources)('Disclaimer')?.richText;

  const formHeaderImage = getImage('Form header image');
  const backgroundImage = getImage('Background image');

  const titleCompleted = t('Title completed');
  const title = t('Title');
  const description = t('Description');
  const submitButtonText = t('Submit button text');
  const buttonLoadingText = t('Button loading text', 'Loading...');
  const errorText = t('Error text');

  const listId =
    t(`Klaviyo-listid-${currency.toUpperCase()}`) ||
    getStoreVariable(getStoreByCurrency(currency), 'NEXT_PUBLIC_KLAVIYO_NEWSLETTER_ID');

  const emailLabel = getFormLabel('email');

  const additionalFieldsString = t('Additional fields');
  const additionalFields = additionalFieldsString
    ? additionalFieldsString.split(',').filter(Boolean)
    : [];

  const fields = [...additionalFields, 'email'];
  const fieldExists = name => fields.includes(name);

  const firstNameLabel = getFormLabel('first name');
  const cityLabel = getFormLabel('city');
  const countryLabel = getFormLabel('country');
  const genderLabel = getFormLabel('gender');
  const maleLabel = getFormLabel('gender option male');
  const femaleLabel = getFormLabel('gender option female');
  const birthDateLabel = getFormLabel('birthdate');

  const recommmendedCountryCodes = getRecommendedCountryCodes(store, locale);

  useEffect(() => {
    const queryValues = getQueryValues(query);
    setValues(previous => ({ ...previous, ...queryValues }));
  }, [query]);

  const accessToken = customer?.customerAccessToken?.accessToken;
  useEffect(() => {
    const setCustomerFields = async () => {
      try {
        const customerResponse = await fetch(
          `/api/shopify/account?${new URLSearchParams({ accessToken })}`,
          {
            headers: { currency },
          }
        );
        const fullCustomer = await customerResponse.json();
        setValues(prevState => {
          return {
            ...prevState,
            ...Object.keys(fullCustomer).reduce(
              (validValues, key) => ({
                ...validValues,
                [key]: fullCustomer[key] || prevState[key] || '',
              }),
              {}
            ),
          };
        });
      } catch (e) {
        // No catch
      }
    };

    if (accessToken) {
      setCustomerFields();
    }
  }, [accessToken, currency]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [isCompleted]);

  const submit = async e => {
    e.preventDefault();

    setError(null);
    setLoading(true);

    const enabledFieldValues = Object.keys(values).reduce(
      (filtered, key) => (fieldExists(key) ? { ...filtered, [key]: values[key] } : filtered),
      {}
    );

    const response = await fetch('/api/newsletter/subscribe', {
      method: 'POST',
      headers: { currency },
      body: JSON.stringify({
        ...enabledFieldValues,
        listId,
        $source: `[Website] ${formName}`,
      }),
    });

    if (response.status === 201) {
      trackInWindow({
        event: trackEvents.newsletterSignUpLegacy,
      });
      trackInWindow({
        event: trackEvents.newsletterSignUp,
      });
      setCompleted(true);
    } else if (response.status === 400) {
      const json = await response.json();
      setError(json.message || errorText);
    } else {
      setError(errorText);
    }

    setLoading(false);
  };

  const form = (
    <form
      onSubmit={submit}
      className="flex w-full flex-col items-start gap-[20px] font-nimbus-sans-d text-[12px] leading-[1.3] lg:pr-[50px]"
    >
      <input
        onChange={inputChange}
        value={values.email}
        placeholder={emailLabel}
        name="email"
        type="email"
        required
        className="input w-full"
      />
      {fieldExists('firstName') && (
        <input
          onChange={inputChange}
          value={values.firstName}
          placeholder={firstNameLabel}
          required
          name="firstName"
          className="input w-full"
          maxLength="40"
        />
      )}
      {fieldExists('gender') && (
        <fieldset>
          <label htmlFor="gender" className="mb-[15px] inline-block">
            {genderLabel}
          </label>
          <div className="flex gap-[14px]">
            <span className="flex items-center">
              <input
                onChange={inputChange}
                checked={values.gender === 'male'}
                required
                name="gender"
                id="male"
                type="radio"
                value="male"
                className="mr-[10px]"
              />
              <label htmlFor="male">{maleLabel}</label>
            </span>
            <span className="flex items-center">
              <input
                onChange={inputChange}
                checked={values.gender === 'female'}
                required
                name="gender"
                id="female"
                type="radio"
                value="female"
                className="mr-[10px]"
              />
              <label htmlFor="female">{femaleLabel}</label>
            </span>
          </div>
        </fieldset>
      )}
      {fieldExists('city') && (
        <input
          onChange={inputChange}
          value={values.city}
          placeholder={cityLabel}
          required
          name="city"
          maxLength="40"
          className="input w-full"
        />
      )}
      {fieldExists('country') && (
        <Select onChange={inputChange} value={values.country} required name="country" id="country">
          <option value="">{countryLabel}</option>
          {recommmendedCountryCodes
            .map(countryCode => Object.entries(countries).find(([code]) => code === countryCode))
            .filter(Boolean)
            .map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          <option value="" disabled>
            ──────
          </option>
          {Object.entries(countries)
            .filter(([code]) => !recommmendedCountryCodes.includes(code))
            .map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
        </Select>
      )}
      {fieldExists('birthDate') && (
        <fieldset className="w-full">
          <label htmlFor="birth_date" className="mb-[15px] inline-block">
            {birthDateLabel}
          </label>
          <input
            value={values.birthDate}
            onChange={inputChange}
            required
            className="input w-full"
            name="birthDate"
            id="birthDate"
            type="date"
            min="1920-01-01"
            max="2022-01-01"
          />
        </fieldset>
      )}
      <fieldset className="w-full">
        <button className="button w-full" type="submit">
          {loading ? buttonLoadingText : submitButtonText}
        </button>
        {error && <p className="text-red-500 mt-[5px]">{error}</p>}
      </fieldset>
      {disclaimerRichText && (
        <div className="mr-[20px] text-[10px] leading-[1.2] text-[#676767] md:mr-0">
          <RichText data={disclaimerRichText} options={richTextOptions(disclaimerRichText)} />
        </div>
      )}
    </form>
  );

  return (
    <div className="mx-auto flex max-w-[1920px] flex-col-reverse text-blue md:flex-row md:justify-between">
      <div className="flex w-full flex-col items-center px-[30px] pb-[30px] pt-[60px] md:pb-[120px] md:pt-[150px]">
        <div className="md:max-w-[375px] lg:w-full lg:max-w-[50%]">
          <div className="mb-[30px] px-[25px] text-center md:mb-[35px] md:px-0 md:text-left">
            {formHeaderImage && (
              <SiteImage
                src={formHeaderImage}
                priority
                quality={100}
                className="mx-auto mb-[15px] max-w-[230px] md:mx-0"
                layout="responsive"
              />
            )}
            {isCompleted ? (
              <>
                {titleCompleted && (
                  <h1 className="mb-[20px] font-nimbus-sans-extd-d text-[20px] font-bold uppercase leading-[0.9] tracking-[-0.02em] md:text-[35px]">
                    {titleCompleted}
                  </h1>
                )}
              </>
            ) : (
              <>
                {title && (
                  <h1 className="mb-[20px] font-nimbus-sans-extd-d text-[20px] font-bold uppercase leading-[0.9] tracking-[-0.02em] md:text-[35px]">
                    {title}
                  </h1>
                )}
                {description && (
                  <p className="font-nimbus-sans-d text-[12px] leading-[1.3] md:max-w-[max(40%,230px)]">
                    {description}
                  </p>
                )}
              </>
            )}
          </div>
          {!isCompleted && form}
        </div>
      </div>
      <div className="w-full md:max-w-[min(35%,750px)] md:pb-[200px] xl:pr-[16px]">
        <div className="relative flex w-full justify-center bg-gray-400 pt-[65%] md:min-h-[500px] md:pt-[115%]">
          <SiteImage src={backgroundImage} priority />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
