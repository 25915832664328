import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { isString } from 'underscore';
import { SiteLink } from 'components/shared';
import { getLinkHref } from 'lib/utils/helpers';

const addSegmentNewLines = segment => {
  if (isString(segment)) {
    return segment.split('\n').reduce((segments, textSegment, i) => {
      // eslint-disable-next-line react/no-array-index-key
      return [...segments, i > 0 && <br key={i} />, textSegment];
    }, []);
  }

  return segment;
};

export const addNewLines = segments => segments.map(addSegmentNewLines);

const richTextOptions = richText => {
  const hyperlink = richText?.links?.entries?.hyperlink || [];
  const getLinkedEntry = linkId => hyperlink.find(l => l?.sys?.id === linkId);

  return {
    renderNode: {
      [BLOCKS.HEADING_1]: (_, children) => (
        <h1 className="mb-[30px] font-nimbus-sans-extd-d text-[35px] font-bold uppercase leading-[0.925] lg:mb-[40px] lg:text-[70px]">
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (_, children) => (
        <h2 className="mb-[30px] font-nimbus-sans-extd-d text-[20px] font-bold uppercase leading-[0.8]">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_3]: (_, children) => (
        <h2 className="mb-[30px] font-nimbus-sans-extd-d text-[14px] font-bold uppercase leading-[0.8]">
          {children}
        </h2>
      ),
      [BLOCKS.HEADING_4]: (_, children) => (
        <h4 className="mb-[12px] font-nimbus-sans-d font-bold">{children}</h4>
      ),
      [BLOCKS.HEADING_5]: (_, children) => <p className="text-heading-5">{children}</p>,
      [BLOCKS.UL_LIST]: (_, children) => (
        <ul className="mb-[25px] list-disc pl-[25px]">{children}</ul>
      ),
      [BLOCKS.LIST_ITEM]: (_, children = []) => {
        const addBottomMargin = c =>
          c.type === 'p'
            ? { ...c, props: { ...c.props, className: `${c.props.className} mb-[4px]` } }
            : c;
        const content = children.map(addBottomMargin);

        return <li className="pl-[7px]">{content}</li>;
      },
      [BLOCKS.PARAGRAPH]: (_, children = []) => {
        const isEmpty = children.length === 1 && children[0] === '';
        if (isEmpty) {
          return <br />;
        }

        return <p>{addNewLines(children)}</p>;
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const {
          data: {
            target: {
              sys: { id },
            },
          },
        } = node;

        const link = getLinkedEntry(id);

        if (!link) {
          return children;
        }

        return (
          <SiteLink href={getLinkHref(link)} className="underline">
            {children}
          </SiteLink>
        );
      },
    },
  };
};

export default richTextOptions;
