import { Image, Swiper } from 'components/shared';
import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import { useRef } from 'react';
import { useIsDesktop } from 'hooks';
import FitGuideCustomVideoPlayer from './customVideoPlayer';
import FitDetail from './fitDetail';

const NoSwiper = ({ children }) => (
  <div
    style={{
      'grid-template-columns': `repeat(${children.length}, max-content)`,
    }}
    className="grid w-full place-content-center gap-x-4 [&>button]:aspect-[0.75] [&>button]:!w-[22.5rem] [&>div]:w-[11.971rem]"
  >
    {children}
  </div>
);

const AdaptiveSwiper = ({ children }) => (
  <Swiper
    className="[&_.center-slides]:!justify-center"
    slidesPerView={1.4}
    spaceBetween={4}
    slidesOffsetBefore={16}
    slidesOffsetAfter={16}
    // onSlideChange={() => setRefresh(r => r + 1)}
    hideButtons
    breakpoints={{
      768: {
        slidesPerView: 3.9,
        slidesOffsetAfter: 48,
        slidesOffsetBefore: 48,
        spaceBetween: 16,
      },
    }}
    scrollbar={{
      el: '.swiper-scrollbar-fit-guide',
      draggable: true,
      hide: false,
    }}
    slideToClickedSlide
  >
    {children}
  </Swiper>
);

const FitTypeSlider = ({ fitTypes, currentFitType, setCurrentFitType }) => {
  const fitDetailWrapper = useRef(null);
  const isDesktop = useIsDesktop();

  const FitTypesContainer =
    (isDesktop && fitTypes?.length > 3) || (!isDesktop && fitTypes.length > 1)
      ? AdaptiveSwiper
      : NoSwiper;

  const trackFitLink = fit =>
    trackInWindow({
      event: trackEvents.fitGuide,
      page_path: window.location.pathname,
      page_title: document.title,
      element_clicked: `Discover more - ${fit.title}`,
    });

  const handleFitTypeClick = ({ i, title }) => {
    setCurrentFitType(i);
    trackFitLink(title);
    setTimeout(() => {
      const yOffset = -20;
      const y = fitDetailWrapper.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }, 100);
  };

  return (
    <>
      <FitTypesContainer>
        {fitTypes.filter(Boolean).map((fitType, i) => {
          const { title, tags, media: fitMedia } = fitType;
          return (
            <button
              type="button"
              onClick={handleFitTypeClick.bind(this, { i, title })}
              key={title}
              className={`${
                currentFitType === i || currentFitType === null ? 'opacity-100' : 'opacity-50'
              } ${
                currentFitType === i ? ' border border-solid border-blue ' : ''
              } relative isolate block aspect-[0.75] w-full px-4 md:px-0`}
            >
              {fitMedia && (
                <div className="z-10">
                  {fitMedia?.video || fitMedia?.vimeoUrl ? (
                    <FitGuideCustomVideoPlayer
                      src={fitMedia?.vimeoUrl || fitMedia?.video?.url}
                      image={fitMedia.image}
                      onToggle={() =>
                        trackInWindow({
                          event: trackEvents.fitGuide,
                          page_path: window.location.pathname,
                          page_title: document.title,
                          element_clicked: `Video - ${title}`,
                        })
                      }
                    />
                  ) : (
                    <Image src={fitMedia.image} loading={i <= 3 ? 'eager' : 'lazy'} />
                  )}
                </div>
              )}
              <div className="absolute bottom-6 left-6 top-auto z-20 h-fit w-fit items-start justify-start text-left">
                <p className="text-heading-5 mb-5 uppercase text-white drop-shadow-md lg:mb-6">
                  {title}
                </p>
                {tags.map(tag => (
                  <p key={tag} className="text-body-2 text-white drop-shadow-md">
                    {tag}
                  </p>
                ))}
              </div>
            </button>
          );
        })}
      </FitTypesContainer>

      <div ref={fitDetailWrapper} className="px-4 md:mt-20 lg:px-32">
        {currentFitType !== null && <FitDetail fitType={fitTypes[currentFitType]} />}
      </div>
    </>
  );
};

export default FitTypeSlider;
