import { useState, useMemo } from 'react';
import { useFetchContentfulProductsInShopify, useScrollLock } from 'hooks';
import { filterByType, getLinkHref } from 'lib/utils/helpers';
import { trackEvents, trackInWindow } from 'hooks/tracking/track';
import { getResource, getResourceText } from 'lib/resources';
import { useRouter } from 'next/router';
import { Image, ProductCard, SiteLink } from 'components/shared';
import Link from 'next/link';
import sizesString from 'lib/sizesString';
import { ArrowLeft, Close, Spinner } from 'components/shared/icons/ui';
import { useTransition, animated } from 'react-spring';
import springConfigs from 'lib/utils/springConfigs';
import Zoom from './zoom';

const PageLookBook = ({
  resources: {
    slug,
    globalModule: {
      resourcesCollection: { items: pageResources },
      defaultResources: lookbookResources,
    },
  },
}) => {
  const [shopLook, setShopLook] = useState(false);

  const {
    query: { look = null, ...restQuery },
    pathname,
  } = useRouter();

  const collections = pageResources.filter(filterByType('SectionLookbookGroup'));
  const collection = collections.find(({ resourcesCollection: { items } }) =>
    items.find(({ name }) => name === look)
  );
  const { withGap } = collections?.[0];

  const looks = useMemo(
    () => collection?.resourcesCollection.items.filter(Boolean) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [collection?.name]
  );

  const links = getResource(lookbookResources)('Menu')?.linksCollection.items || [];

  const { data: shopLookProducts = [], isValidating } = useFetchContentfulProductsInShopify(
    shopLook
      ? looks.find(({ name }) => name === shopLook)?.collectionCollection?.items?.slice(0, 4) || []
      : []
  );

  const shopLookTransition = useTransition(
    [look && shopLook && !isValidating ? shopLookProducts : false].filter(Boolean),
    {
      from: { transform: 'translateY(100%)', opacity: 0 },
      enter: { transform: 'translateY(0%)', opacity: 1 },
      leave: { transform: 'translateY(100%)', opacity: 0 },
      config: springConfigs.accordionContent,
    }
  );

  useScrollLock(looks.length);
  const dictionary = word => getResourceText(lookbookResources)(`lookbook-${word}Text`, word);

  return (
    <main className="py-6 lg:pt-10">
      {Boolean(links.length) && (
        <nav className="mx-auto flex w-full items-center justify-center gap-1 pt-3 font-nimbus-sans-extd-d text-[12px] uppercase lg:pt-6">
          {links.map(link => (
            <SiteLink
              key={link.name}
              className={`flex items-center gap-[inherit] uppercase after:block after:h-[1.5px] after:w-1 after:bg-black last:after:content-none ${
                link.link?.slug === slug ? 'underline' : ''
              }`}
              href={getLinkHref(link.link)}
            >
              {link.text || link.name}
            </SiteLink>
          ))}
        </nav>
      )}
      <section className={`${withGap ? ' px-4 ' : ''} flex flex-col gap-6 py-3 lg:gap-32`}>
        {collections
          .filter(Boolean)
          .map(
            ({
              name,
              title,
              description,
              resourcesCollection: { items: collectionLooks } = { items: [] },
            }) => (
              <div key={name} className="flex flex-col gap-8 lg:gap-16">
                <header className="flex flex-col items-center justify-center gap-6 text-center">
                  <h1 className="lg:max-auto font-nimbus-sans-extd-d text-[30px] font-bold uppercase leading-[0.9] lg:max-w-[950px] lg:text-[100px]">
                    {title}
                  </h1>
                  {description && (
                    <p className="text-[15px] leading-[1.4] lg:mx-auto lg:max-w-[700px]">
                      {description}
                    </p>
                  )}
                </header>
                <div
                  className={`${
                    withGap ? ' gap-[15px] ' : ''
                  } relative grid grid-cols-2  lg:grid-cols-4`}
                >
                  {collectionLooks
                    .filter(l => l.thumbnail)
                    .map(l => (
                      <Link
                        key={name + l.name}
                        passHref
                        shallow
                        href={{ pathname, query: { ...restQuery, look: l.name } }}
                      >
                        <a className="block" href="_">
                          <Image
                            className="relative pt-[130%]"
                            src={l.thumbnail}
                            sizes={sizesString('50vw', '20vw')}
                            loading="lazy"
                            layout="fill"
                            alt={l.title}
                          />
                        </a>
                      </Link>
                    ))}
                </div>
              </div>
            )
          )}
      </section>
      <Zoom
        looks={looks}
        look={look}
        mobileCloseButton={
          <Link href={{ pathname, query: restQuery }} shallow passHref>
            <a
              href="_"
              className="button absolute top-[var(--shiftContent)] flex w-full items-center gap-2 self-center justify-self-end border-b border-[rgba(0,0,0,0.05)] bg-white py-2 text-blue lg:hidden"
            >
              <ArrowLeft className="h-[14px] w-[8.4px]" />
              <span className="pt-[1.75px]">{dictionary('return')}</span>
            </a>
          </Link>
        }
        showShop={shopLook}
        onChange={() =>
          trackInWindow({
            event: trackEvents.swipeTheLook,
            page_path: window.location.pathname,
            page_title: document.title,
          })
        }
        renderLook={({ name, thumbnail, title, description }) => (
          <article key={name} className="relative w-full lg:flex lg:items-end lg:px-4">
            <header className="relative flex flex-col items-center lg:w-[35vw] lg:shrink-0">
              <Image
                className="relative w-full pt-[130%]"
                src={thumbnail}
                loading="eager"
                sizes={sizesString('90vw', '30vw')}
              />
              <button
                type="button"
                onClick={() => {
                  setShopLook(name);
                  trackInWindow({
                    event: trackEvents.shopTheLook,
                    page_path: window.location.pathname,
                    page_title: document.title,
                    lookbook_name: name || '',
                  });
                }}
                disabled={isValidating && name === shopLook}
                className="swiper-no-swiping button absolute bottom-8 flex w-48 self-center bg-white text-blue disabled:bg-white"
              >
                {isValidating && name === shopLook ? (
                  <Spinner className="h-[1.5em]" />
                ) : (
                  <span>{dictionary('shopTheLook')}</span>
                )}
              </button>
              <Link href={{ pathname, query: restQuery }} shallow passHref>
                <a
                  href="_"
                  role="button"
                  className="swiper-no-swiping button absolute right-4 top-4 hidden bg-white px-3 py-3 text-blue lg:block"
                >
                  <Close className="h-3 w-3" />
                </a>
              </Link>
            </header>
            <aside className="flex w-full flex-col gap-4 overflow-x-hidden px-4 pb-40 pt-8 lg:p-12">
              <Image
                bgColor={false}
                className="absolute -bottom-12 -right-[24%] -z-10 h-[200px] w-[360px] rotate-[-12deg] lg:hidden"
                src={{ url: '/images/scissors.png' }}
              />
              {title && (
                <h3 className="font-nimbus-sans-extd-d text-[35px] font-bold uppercase leading-[90%]">
                  {title}
                </h3>
              )}
              {description && (
                <p className="text-[15px] leading-[1.4] lg:max-w-[300px] ">{description}</p>
              )}
            </aside>
          </article>
        )}
      />
      {shopLookTransition(({ transform, opacity }, products) => (
        <>
          <animated.button
            onClick={() => setShopLook()}
            style={{ opacity }}
            className="fixed inset-0 z-[20000] h-full w-full bg-blue bg-opacity-30"
          />
          <animated.div
            style={{ transform }}
            className="fixed inset-0 z-[20010] bg-white lg:top-[unset] lg:max-h-full lg:overflow-y-auto lg:pt-0"
          >
            <div className="relative flex h-full flex-col gap-6 px-4 py-6">
              <header className="flex items-center lg:justify-center">
                <h3 className="font-nimbus-sans-extd-d text-[20px] font-bold uppercase lg:text-[35px]">
                  {dictionary('shopTheLook')}
                </h3>
                <button
                  className="ml-auto text-[14px] lg:hidden"
                  type="button"
                  onClick={() => setShopLook()}
                >
                  {dictionary('close')}
                </button>
              </header>
              <button
                type="button"
                className="absolute right-6 top-6 hidden lg:block"
                onClick={() => setShopLook()}
              >
                <Close className="w-4" />
              </button>
              <main className="hide-scrollbar grid h-fit max-h-full w-full grid-cols-2 gap-2 overflow-y-auto overflow-x-hidden lg:grid-cols-4">
                {products.map(product => (
                  <ProductCard
                    key={product.slug}
                    product={product}
                    t={(value, fallback = value) =>
                      getResourceText(lookbookResources)(value) || fallback
                    }
                  />
                ))}
              </main>
            </div>
          </animated.div>
        </>
      ))}
    </main>
  );
};

export default PageLookBook;
