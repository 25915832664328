import { Image, Swiper } from 'components/shared';
import { useIsDesktop, useUI } from 'hooks';
import springConfigs from 'lib/utils/springConfigs';
import { useRouter } from 'next/router';
import { createRef, useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { animated, useTransition } from 'react-spring';
import supportsIntersectionObserver from 'lib/supportsIntersectionObserver';

const Zoom = ({ mobileCloseButton, looks, renderLook, onChange }) => {
  const { shiftContent, shiftContentDesktop } = useUI();
  const isDesktop = useIsDesktop();

  const {
    query: { look = null, ...restQuery },
    push,
    isReady,
  } = useRouter();

  const [init, setInit] = useState(!!isDesktop);

  const lookRefs = looks.map(() => createRef());
  const lookIndex = looks.findIndex(({ name }) => name === look);
  const lookRef = isDesktop ? null : lookRefs[lookIndex];

  const container = typeof window !== 'undefined' ? document.getElementById('modal-root') : null;

  const transitions = useTransition([looks.length > 0 ? looks : false].filter(Boolean), {
    from: { opacity: 0, transform: 'translateX(20%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0 },
    config: { ...springConfigs.accordionContent, duration: looks.length > 0 ? undefined : 0 },
  });

  const updateUrl = useCallback(
    l => {
      if (isReady)
        push({ query: { ...restQuery, look: l } }, undefined, {
          shallow: true,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(restQuery), isReady]
  );

  // Initial scroll to look on mobile
  useEffect(() => {
    if (typeof window === 'undefined') return;

    // Reset when going from no look > look
    if (!lookRef && init) setInit(false);

    if (lookRef && !init) {
      lookRef.current.scrollIntoView({
        block: 'start',
      });
      setInit(true);
    }
  }, [lookRef, init]);

  // Update url on scroll into view on mobile
  useEffect(() => {
    if (typeof window === 'undefined' || !supportsIntersectionObserver()) return () => {};

    const currentLookRefs = lookRefs.map(l => l.current).filter(Boolean);
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) updateUrl(entry.target.dataset.look);
      },
      { rootMargin: '200px 0px 0px 0px', threshold: 0.6 }
    );

    if (init) currentLookRefs.forEach(l => observer.observe(l));

    return () => currentLookRefs.forEach(l => observer.unobserve(l));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(lookRefs), init, updateUrl]);

  useEffect(() => {
    if (look && onChange) onChange();
  }, [look]);

  return container
    ? createPortal(
        transitions((style, visibleLooks) => (
          <animated.div
            style={{
              '--shiftContent': shiftContent,
              '--shiftContentDesktop': shiftContentDesktop,
              ...style,
            }}
            className={`fixed top-0 z-10 flex h-full w-full items-center justify-center bg-white pt-[var(--shiftContent)] will-change-[opacity] md:pt-[var(--shiftContentDesktop)] lg:pt-[calc(var(--shiftContentDesktop)+4rem)] lg:pb-16 `}
          >
            <Image
              className="absolute top-[62px] right-[16%] hidden h-[200px] w-[360px] rotate-[-12deg] lg:block"
              src={{ url: '/images/scissors.png' }}
              bgColor={false}
              loading="lazy"
            />
            <div className="h-full w-full overflow-auto overflow-x-hidden p-4 pt-14 lg:hidden">
              {visibleLooks.map((l, i) => (
                <div key={l.name} data-look={l.name} ref={lookRefs[i]} className="scroll-m-16">
                  {renderLook(l)}
                </div>
              ))}
            </div>
            <Swiper
              className="hidden h-full w-full items-center lg:flex"
              slidesPerView={1.2}
              spaceBetween={0}
              initialSlide={lookIndex || 0}
              hideButtons
              mousewheel
              loop
              onSlideChange={({ realIndex: activeIndex }) => updateUrl(looks[activeIndex].name)}
            >
              {visibleLooks.map(renderLook)}
            </Swiper>
            {mobileCloseButton}
          </animated.div>
        )),
        container
      )
    : null;
};

export default Zoom;
