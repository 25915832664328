import { Image as SiteImage } from 'components/shared';
import { filterByType, createMediaGetterFromCollection } from 'lib/utils/helpers';

const PageCareers = ({ resources }) => {
  const globalModule = resources?.globalModuleCollection?.items[0];
  const { resourcesCollection } = globalModule;
  const jobs = globalModule?.jobOpenings || [];

  const getMediaByName = createMediaGetterFromCollection(resourcesCollection);
  const careersBanner = getMediaByName('careers_banner');

  const careersCopyCollection = resourcesCollection?.items
    .filter(filterByType('ComponentMicrocopy'))
    .filter(item => item.name.includes('careers-subtitle'))
    .map(copy => copy.text);

  const careersTitle = resourcesCollection?.items
    .filter(filterByType('ComponentMicrocopy'))
    .filter(item => item.name.includes('careers-title'))
    .map(copy => copy.text);

  const jobsList = jobs.reduce((sorted, job) => {
    sorted[job.tmp_department] = jobs.filter(
      item => item.tmp_department && item.tmp_department.includes(job.tmp_department)
    );
    return sorted;
  }, {});

  return (
    <>
      <section className="lg:mx-[auto] lg:max-w-[1460px]">
        <div className="text-center text-blue">
          <div className="px-[47px] pt-[40px] pb-[55px] md:mx-auto md:max-w-[950px] lg:pb-[60px]">
            <h1 className="mb-[10px] font-nimbus-sans-extd-d text-[30px] font-bold  uppercase tracking-[-0.02em] lg:mb-[14px] lg:text-[100px] lg:leading-[100%]">
              {careersTitle}
            </h1>
            <p className="font-nimbus-sans-d text-[12px] leading-[16.6px] lg:text-[15px] lg:leading-[21px] xl:px-[70px]">
              {careersCopyCollection}
            </p>
          </div>
          <div className="relative h-[588px] lg:h-[883px]">
            <SiteImage src={careersBanner} />
          </div>
        </div>
        <div className="mt-[56px] px-[31px] lg:mt-[84px] lg:px-[167px] lg:pb-[52px]">
          {jobsList &&
            Object.values(jobsList).map(list => (
              <div>
                <h1 className="mb-[9px] font-nimbus-sans-extd-d text-[20px] font-bold uppercase tracking-[-0.02em] lg:mb-[7px]">
                  {list[0]?.tmp_department}
                </h1>
                {list.map(({ title, type, job_url, tmp_location }) => (
                  <div className="last:mb-[56px] lg:last:mb-[52px] lg:last:border-b">
                    <a
                      className="flex flex-col border-t pt-[20px] pb-[14px] font-nimbus-sans-d text-[15px] leading-[21px] lg:flex-row"
                      href={job_url}
                      target="_blank"
                      rel="noreferrer"
                      alt="job-link"
                    >
                      <h2 className="mb-[10px] lg:mb-0 lg:w-[65%]">{title}</h2>
                      <p className="mb-[10px] flex-grow text-blue opacity-30 lg:mb-0">{type}</p>
                      <p className="text-blue opacity-30">{tmp_location}</p>
                    </a>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </section>
    </>
  );
};

export default PageCareers;
