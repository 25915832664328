import { Image } from 'components/shared';
import { useState, useRef, useEffect } from 'react';

const FitGuideCustomVideoPlayer = ({ src, image, resetVideo, onToggle }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  useEffect(() => {
    setIsTouched(false);
    setIsPlaying(false);
    videoRef.current.pause();
  }, [resetVideo]);

  const handleToggleVideo = e => {
    e.stopPropagation();
    e.preventDefault();
    if (onToggle) onToggle();

    if (!isTouched) {
      setIsTouched(true);
    }

    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="absolute inset-0 h-full w-full">
      {!isTouched && <Image src={image} />}
      <video
        ref={videoRef}
        className={`absolute inset-0 z-0 object-cover ${
          isTouched ? 'h-full w-full' : 'h-0 w-full'
        }`}
        playsInline
        disableRemotePlayback
        loop
        muted
      >
        <source src={src} type="video/mp4" />
      </video>
      <div className="absolute bottom-[5px] right-[5px] z-10 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-white">
        <button onClick={handleToggleVideo} className="inline-block h-full w-full" type="button">
          {isPlaying ? (
            <div className="pointer-events-none flex items-center justify-center">
              <span className="mr-[4px] inline-block h-[24px] w-[5px] bg-blue" />
              <span className="inline-block h-[24px] w-[5px] bg-blue" />
            </div>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="#041C2C"
              className="pointer-events-none mx-auto"
            >
              <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z" />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default FitGuideCustomVideoPlayer;
