import { first } from './utils/helpers';

const countryCodesPerStoreLocale = {
  'EU-EN': ['NL', 'DE', 'GB', 'US', 'BE', 'FR', 'AT', 'ES'],
  'EU-DE': ['DE', 'AT'],
  'EU-NL': ['NL', 'BE'],
  'UK-EN': ['GB'],
  'US-EN': ['US'],
};

const getRecommendedCountryCodes = (store, locale) => {
  const storeLocale = `${store.toUpperCase()}-${locale.toUpperCase()}`;

  return countryCodesPerStoreLocale[storeLocale] || first(countryCodesPerStoreLocale);
};

export default getRecommendedCountryCodes;
