import contentfulLoader from 'lib/contentful/imageLoader';
import shopifyLoader from 'lib/shopify/imageLoader';
import Img from 'next/image';

const imageLoaders = {
  'images.ctfassets.net': contentfulLoader,
  'cdn.shopify.com': shopifyLoader,
};

const Image = ({
  src,
  alt,
  className = '',
  layout = 'fill',
  priority = false,
  loading = !priority ? 'lazy' : 'eager',
  sizes: imageSizes,
  onLoad,
  bgColor = true,
  objectFit = layout === 'fill' ? 'cover' : 'fill',
}) => {
  const sizes = ['fill', 'responsive'].includes(layout) ? imageSizes : undefined;

  const imageDesktop = src?.desktop;
  const imageMobile = src?.mobile;

  const renderImage = image => {
    const imageSrc = image?.baseUrl || image?.url || image?.transformedSrc;

    const loader = imageSrc.startsWith('https://')
      ? imageLoaders[new URL(imageSrc).host] || undefined
      : undefined;

    return (
      <Img
        src={`${imageSrc}`}
        height={layout === 'fill' ? null : image?.height}
        width={layout === 'fill' ? null : image?.width}
        objectFit={objectFit}
        layout={layout}
        alt={alt || image.title || process.env.NEXT_PUBLIC_SITENAME}
        loading={loading}
        loader={loader}
        priority={priority}
        sizes={sizes}
        onLoad={onLoad}
      />
    );
  };

  return (
    <div className={`${className} ${bgColor && 'bg-gray-50'}`}>
      {(src?.baseUrl || src?.url || src?.transformedSrc) && renderImage(src)}
      {imageMobile?.baseUrl && renderImage(imageMobile)}
      {imageDesktop?.baseUrl && renderImage(imageDesktop)}
    </div>
  );
};

export default Image;
