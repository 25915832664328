import { useEffect, useMemo, useRef, useState } from 'react';
import {
  filterByType,
  createNavGetterFromCollection,
  createMultipleMicroCopyGetterFromCollection,
} from 'lib/utils/helpers';
import Breadcrumbs from 'components/shared/breadcrumbs';
import { getResourceText } from 'lib/resources';
import { uniqBy } from 'lodash';
import { Icon, Image, SiteLink, Swiper } from 'components/shared';
import { useIsDesktop } from 'hooks';
import { useRouter } from 'next/router';
import FitTypeSlider from './fitTypeSlider';

const AdaptiveSwiper = ({ children }) => (
  <Swiper
    className="[&_.center-slides]:!justify-center"
    spaceBetween={8}
    slidesPerView={2.8}
    slidesOffsetBefore={16}
    slidesOffsetAfter={16}
    hideButtons
    breakpoints={{
      768: {
        slidesPerView: 'auto',
      },
    }}
    scrollbar={{
      el: '.swiper-scrollbar-fit',
      draggable: true,
      hide: false,
    }}
  >
    {children}
  </Swiper>
);

const NoSwiper = ({ children }) => (
  <div
    style={{
      'grid-template-columns': `repeat(${children.length}, max-content)`,
    }}
    className="w-100% grid place-content-center gap-x-2 [&>div]:w-[11.971rem]"
  >
    {children}
  </div>
);

const PageFitGuide = ({ resources }) => {
  const { resourcesCollection } = resources?.globalModuleCollection?.items[0];
  const isDesktop = useIsDesktop();
  const [activeFit, setActiveFit] = useState(null);
  const [currentFitType, setCurrentFitType] = useState(null);
  const { asPath } = useRouter();
  const currentSlug = asPath.replaceAll('/', '');
  const fitTypesRef = useRef(null);

  const getNavByName = createNavGetterFromCollection(resourcesCollection);
  const getMicrocopiesByNames = createMultipleMicroCopyGetterFromCollection(resourcesCollection);

  const breadcrumbs = getNavByName('Breadcrumbs') || [];
  const [fitGuideTitle, fitGuideDescription, minLabel, maxLabel, homeLabel] = getMicrocopiesByNames(
    'fit-guide-title',
    'fit-guide-description',
    'fit-guide-min-label',
    'fit-guide-max-label',
    'Home'
  );
  const fitGuideLinks = resourcesCollection?.items?.filter(filterByType('ComponentLink'));
  const shopButtonText = getResourceText(resourcesCollection?.items)(
    'fit-guide-shopButtonText',
    'Shop %s jeans'
  );

  const fits = uniqBy(resourcesCollection?.items?.filter(filterByType('ComponentFitType')));
  const FitsContainer = fits?.length > 6 || !isDesktop ? AdaptiveSwiper : NoSwiper;

  useEffect(() => {
    setActiveFit(null);
  }, [asPath]);

  // modify fitTypes data to include the shopButtonText
  const fitTypes = useMemo(
    () =>
      activeFit?.availableJeanTypesCollection?.items?.reduce((acc, collection) => {
        if (filterByType('PageCollection')(collection)) {
          const availableFits = collection.availableFitsCollection.items;

          acc.push(
            ...availableFits.map(item => ({
              ...item,
              ...collection,
              shopButtonText: shopButtonText?.replace(/%s/g, collection?.title),
            }))
          );
        }
        return acc;
      }, []) || [],
    [activeFit]
  );

  const handleFitsClick = fit => {
    setActiveFit(fit);
    setCurrentFitType(null);
    setTimeout(() => {
      const yOffset = -50;
      const y = fitTypesRef?.current?.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }, 100);
  };

  return (
    <>
      <section className="pb-24 text-center text-blue md:pb-32">
        {!!breadcrumbs.length && (
          <Breadcrumbs
            items={breadcrumbs}
            homeLabel={homeLabel}
            className="hidden md:flex"
            currentLabel={fitGuideTitle?.replace('<br>', ' ')}
          />
        )}

        <div>
          {fitGuideTitle && (
            <h2 className="text-heading-2 mb-4 mt-16 lg:mb-6 lg:mt-12">
              {fitGuideTitle.split('<br>').map(line => (
                <span key={line} className="block">
                  {line}
                </span>
              ))}
            </h2>
          )}

          {fitGuideDescription && (
            <p className="text-body-2 mb-7 px-[27.5px]">{fitGuideDescription}</p>
          )}

          {fitGuideLinks?.length > 0 && (
            <div className="mb-16 flex justify-center gap-4">
              {fitGuideLinks?.map(link => (
                <SiteLink
                  className={`text-heading-7 uppercase text-blue ${
                    link?.link?.slug === currentSlug ? 'underline opacity-100' : 'opacity-20'
                  }`}
                  key={link?.name}
                  href={link?.link?.slug}
                >
                  {link?.text}
                </SiteLink>
              ))}
            </div>
          )}

          {(minLabel || maxLabel) && (
            <div className="mb-6 flex items-center justify-center gap-2 px-4 text-blue lg:mb-7 lg:gap-4 lg:px-12">
              <p>{minLabel}</p>
              <Icon className="hidden lg:block" type="fullLengthArrow" />
              <Icon className="block lg:hidden" type="fullLengthArrowMobile" />
              <p>{maxLabel}</p>
            </div>
          )}

          <div className="w-full overflow-hidden">
            <div className="mb-7 lg:px-32">
              <FitsContainer>
                {fits?.map(fit => (
                  <div key={fit?.sys?.id} className="swiper-slide text-center md:!max-w-[11.971rem]">
                    <button
                      onClick={handleFitsClick.bind(this, fit)}
                      type="button"
                      className="h-full w-full"
                    >
                      <div
                        className={`${
                          fit.sys.id === activeFit?.sys?.id ? 'border border-solid border-blue' : ''
                        } relative mb-4 aspect-[0.79] w-full md:mb-7`}
                      >
                        <Image src={fit?.thumbnailImage?.image} />
                      </div>
                      <p className="text-heading-7">{fit?.name}</p>
                    </button>
                  </div>
                ))}
              </FitsContainer>
              <div ref={fitTypesRef} />
            </div>

            <div className="md:mt-20">
              {activeFit && !!fitTypes?.length && (
                <FitTypeSlider
                  fitTypes={fitTypes}
                  currentFitType={currentFitType}
                  setCurrentFitType={setCurrentFitType}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageFitGuide;
