export default ({ src, quality, width, height }) => {
  const url = new URL(src);

  const isIE =
    typeof window !== 'undefined' &&
    window.navigator.userAgent &&
    /MSIE|Trident/.test(window.navigator.userAgent);

  const defaultFormat = isIE ? 'jpg' : 'webp';
  const format = url.pathname.endsWith('.gif') ? 'gif' : defaultFormat;

  const params = { fm: format, w: Math.min(width, 2560), q: quality || '75' };
  
  Object.keys(params).forEach(paramKey => url.searchParams.set(paramKey, params[paramKey]));

  return url.href;
};
